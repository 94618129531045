import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowLongRightIcon from "@heroicons/react/24/solid/ArrowLongRightIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import PaperAirplaneIcon from "@heroicons/react/24/outline/PaperAirplaneIcon";
import BuildingLibraryIcon from "@heroicons/react/24/outline/BuildingLibraryIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import ArrowPathRoundedSquareIcon from "@heroicons/react/24/outline/ArrowPathRoundedSquareIcon";
import DocumentArrowDownIcon from "@heroicons/react/24/outline/DocumentArrowDownIcon";
import dayjs from "dayjs";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";

const ICONS = {
  completed: { color: "success", icon: CheckCircleIcon },
  pending: { color: "warning", icon: ExclamationTriangleIcon },
  failed: { color: "error", icon: XMarkIcon },
  canceled: { color: "disabled", icon: XMarkIcon },
};

export default function ({ item, fx_rates, currency, onClick }) {
  const Icon = ICONS[item.status]?.icon;

  return (
    <Grid
      container
      spacing={0}
      onClick={onClick}
      sx={{
        p: 2,
        alignItems: "center",
        borderRadius: 0,
        cursor: onClick ? "pointer" : "inherit",
        "&:not(:last-child)": { borderBottom: "solid 0.5px #eee" },
        "&:hover": onClick ? { border: 0.5, borderColor: "#6366F1" } : {},
      }}
    >
      <Grid item xs={12} md={2}>
        <Typography variant="body2">
          {ICONS[item.status] && (
            <>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <SvgIcon color={ICONS[item.status].color}>
                <Icon />
              </SvgIcon>
              <br />
            </>
          )}
          <I18n l="date.long" v={dayjs(item.date).toDate()} />
        </Typography>
      </Grid>
      <Grid
        item
        xs={7}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        {item.amount < 0 && (
          <Typography variant="body1">
            <b>{item.description}</b>
          </Typography>
        )}
        {item.amount < 0 && <div style={{ width: 15 }} />}
        {item.amount < 0 && (
          <SvgIcon>
            <ArrowLongRightIcon />
          </SvgIcon>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{ width: 28, height: 28, mr: 1 }}
            src={item.bank_account.image}
          />
          <Typography
            variant="body2"
            sx={{ display: { xs: "none", md: "block", whiteSpace: "nowrap" } }}
          >
            {item.bank_account.short_name}
          </Typography>
        </div>
        {item.amount >= 0 && (
          <SvgIcon>
            <ArrowLongRightIcon />
          </SvgIcon>
        )}
        {item.amount >= 0 && <div style={{ width: 15 }} />}
        {item.amount >= 0 && (
          <Typography variant="body1">
            <b>{item.description}</b>
            <div style={{ display: "flex" }}>
              {(item.data?.recipient_name && (
                <Typography
                  variant="subtitle2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {item.app_integration && (
                    <SvgIcon fontSize="small" sx={{ mr: 1 }}>
                      <ArrowPathRoundedSquareIcon />
                    </SvgIcon>
                  )}
                  <SvgIcon fontSize="small">
                    <PaperAirplaneIcon />
                  </SvgIcon>
                  &nbsp;
                  {item.data?.recipient_name}
                </Typography>
              )) || (
                <Typography
                  variant="subtitle2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {item.app_integration && (
                    <SvgIcon fontSize="small" sx={{ mr: 1 }}>
                      <ArrowPathRoundedSquareIcon />
                    </SvgIcon>
                  )}
                  <SvgIcon fontSize="small">
                    <BuildingLibraryIcon />
                  </SvgIcon>
                  &nbsp;
                  <I18n reserve_cash />
                </Typography>
              )}
            </div>
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} md={3} sx={{ textAlign: "right" }}>
        <Typography variant="body1">
          <I18n
            l="currency"
            v={Math.abs(item.amount)}
            currency={item.currency}
          />
        </Typography>
        {item.currency != item.bank_account.currency && (
          <Typography variant="subtitle2">
            {(item.data?.payment_result?.source_amount && (
              <I18n
                l="currency"
                v={item.data.payment_result.source_amount}
                currency={item.bank_account.currency}
              />
            )) ||
              (item.data?.reserve_amount && (
                <I18n
                  l="currency"
                  v={item.data.reserve_amount}
                  currency={item.bank_account.currency}
                />
              )) ||
              (fx_rates && (
                <>
                  <I18n
                    l="currency"
                    v={item.amount * fx_rates[item.currency][currency]}
                    currency={item.bank_account.currency}
                  />
                  <I18n estimated />
                </>
              ))}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        md={1}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {item.pdf_confirmation && (
          <Tooltip
            title={i18n.t("download_bank_statement")}
            sx={{ marginLeft: "auto" }}
          >
            <IconButton
              target="_blank"
              href={routes.confirmation_letter({
                id: item.pdf_confirmation.identifier,
                format: "pdf",
              })}
              onClick={(e) => e.stopPropagation()}
            >
              <SvgIcon>
                <DocumentArrowDownIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}
