import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BuildingLibraryIcon from "@heroicons/react/24/solid/BuildingLibraryIcon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import BankCard from "./BankCard";
import currencyName from "../../../utils/currency-name";

const SYFE_LOGIN = "https://www.syfe.com/login?extension=on";
const CHROME_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/cashwise-assistant/fngbgbjdacalofnlnhadmhghbanikpim";

function InfoGrid({ name, value }) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="subtitle2">
          <I18n t={name} />
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">
          <b>{value}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

function ShowDrawerContent({ bank_account, onClose }) {
  return (
    <Stack spacing={2}>
      <BankCard
        bank={bank_account}
        action={
          <Button variant="contained" href={SYFE_LOGIN} target="_blank">
            <I18n login_to name={bank_account.short_name} />
          </Button>
        }
      >
        <Typography variant="body2">
          <b>
            <I18n currency />
            :&nbsp;{currencyName(bank_account.currency)}
          </b>
        </Typography>
        <Typography variant="body2">
          <I18n estimated_yield />
          :&nbsp;
          <b>
            {bank_account.bank.profile.portfolio_info.find(
              (info) => info.currency == bank_account.currency,
            ).yield_pa || "-"}
          </b>
          %p.a
        </Typography>
        <Typography variant="subtitle2" sx={{ fontSize: "80%" }}>
          <I18n chrome_extension_subtitle name={bank_account.short_name} />
          <br />
          <a href={CHROME_EXTENSION_URL} target="_blank" rel="noreferrer">
            <I18n chrome_extension_install name={bank_account.short_name} />
          </a>
        </Typography>
      </BankCard>
      <Card>
        <CardHeader
          title={i18n.t("deposit_bank_account_info")}
          subheader={i18n.t("deposit_bank_account_subtitle")}
        />
        {bank_account.profile.bank_account_info.map((bank_account_info, i) => (
          <>
            <Divider />
            <CardContent key={i}>
              <Stack spacing={2}>
                <InfoGrid
                  name="bank_name"
                  value={bank_account_info.bank_name}
                />
                <InfoGrid
                  name="bank_code"
                  value={bank_account_info.bank_code}
                />
                <InfoGrid
                  name="bank_swift_code"
                  value={bank_account_info.bank_swift_code}
                />
                <InfoGrid
                  name="bank_account_number"
                  value={bank_account_info.bank_account_number}
                />
                <InfoGrid
                  name="bank_account_name"
                  value={bank_account_info.company_name}
                />
                <InfoGrid
                  name="bank_reference"
                  value={bank_account.profile.reference}
                />
              </Stack>
            </CardContent>
          </>
        ))}
      </Card>
      <Button fullWidth variant="contained" color="primary" onClick={onClose}>
        <I18n close />
      </Button>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("deposit_bank_account_info")}
      icon={BuildingLibraryIcon}
    >
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
