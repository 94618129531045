import React from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("name") })),
});

export default function ({ flash }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form
        method="post"
        action={routes.users_accounts()}
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n sign_up />
          </Typography>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("company_name")}
            helperText={errors.name?.message}
            error={"name" in errors}
            {...register("name")}
            autoFocus
          />
          <SubmitButton type="submit" variant="contained" size="large">
            <I18n sign_up />
          </SubmitButton>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
