import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import BankCard from "./BankCard";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import currencyName from "../../../utils/currency-name";

function TokenObtainError(identifier, handleAccountIntegrationCancel) {
  return (
    <PopupApplicationLayout title="account_integration">
      <Stack spacing={2}>
        <Typography variant="body1">
          <b>
            <I18n
              account_integration_credentials_need_to_be_updated
              name={i18n.t("bank_account")}
            />
          </b>
          &nbsp;
          <I18n click_the_following_button_to_update />
        </Typography>
        <div />
        <Button
          size="large"
          color="warning"
          variant="contained"
          href={routes.initiate_account_integrations({
            identifier,
            continue_select: true,
          })}
        >
          <I18n update_credentials />
        </Button>
        <Button
          onClick={handleAccountIntegrationCancel}
          sx={{ marginLeft: "auto!important", marginTop: "6px!important" }}
        >
          <I18n cancel />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}

function GetBalanceError(handleAccountIntegrationCancel) {
  return (
    <PopupApplicationLayout title="account_integration">
      <Stack spacing={2}>
        <Typography variant="body1">
          <I18n account_integration_get_balance_error />
        </Typography>
        <div />
        <Button variant="contained" onClick={handleAccountIntegrationCancel}>
          <I18n cancel />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}

export default function ({ error, bank, identifier, balance, flash }) {
  const [select, setSelect] = useState();
  const handleAccountIntegrationSelect = () => {
    if (window.opener.handleAccountIntegrationSelect) {
      window.opener.handleAccountIntegrationSelect(identifier, select.currency);
      window.close();
    }
  };
  const handleAccountIntegrationCancel = () => {
    window.opener.handleAccountIntegrationCancel();
    window.close();
  };

  if (error == "get_token")
    return TokenObtainError(identifier, handleAccountIntegrationCancel);
  if (error == "get_balance")
    return GetBalanceError(handleAccountIntegrationCancel);

  return (
    <PopupApplicationLayout title="account_integration" flash={flash}>
      <Stack spacing={2}>
        <Typography variant="body1">
          <I18n select_currency_of_your_account />
        </Typography>
        <div />
        {balance.map((item) => (
          <BankCard
            key={item.currency}
            bank={{ ...bank, currency: item.currency, id: item.currency }}
            select={select}
            onSelectBank={setSelect}
          >
            <Typography variant="body2">
              <I18n currency />
              :&nbsp;&nbsp;&nbsp;
              <b>{currencyName(item.currency)}</b>
            </Typography>
            <Typography variant="body2">
              <I18n current_balance />
              :&nbsp;&nbsp;&nbsp;
              <b>
                <I18n l="number" v={item.amount} delimiter="," precision={2} />
              </b>
            </Typography>
          </BankCard>
        ))}
        {balance.length == 0 && <I18n no_balance_is_found_in_currencies />}
        <div />
        {balance.length > 0 && (
          <Button
            fullWidth
            variant="contained"
            disabled={!select}
            onClick={handleAccountIntegrationSelect}
          >
            <I18n select />
          </Button>
        )}
        {balance.length > 0 && (
          <Button
            onClick={handleAccountIntegrationCancel}
            sx={{ marginLeft: "auto!important", marginTop: "6px!important" }}
          >
            <I18n close />
          </Button>
        )}
        {balance.length == 0 && (
          <Button
            fullWidth
            variant="contained"
            onClick={handleAccountIntegrationCancel}
          >
            <I18n close />
          </Button>
        )}
      </Stack>
    </PopupApplicationLayout>
  );
}
