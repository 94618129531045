import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import I18n from "../common/I18n";
import TransactionRecord from "../transactions/TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";

function ShowDrawerContent({
  currency,
  fx_rates,
  current_user,
  pastItems,
  openCashflowForm,
  openTransactionForm,
}) {
  return (
    <Card>
      <CardContent>
        {pastItems.map(
          (item, i) =>
            (item.date && (
              <CashflowItem
                key={i}
                item={item}
                onClick={() => openCashflowForm(item)}
              />
            )) || (
              <TransactionRecord
                key={i}
                transaction={item}
                currency={currency}
                readOnly={current_user.role_value == "read_only"}
                fx_rates={fx_rates}
                onClick={
                  item.status == "placed"
                    ? () => openTransactionForm(item)
                    : undefined
                }
              />
            ),
        )}
        {pastItems.length == 0 && <I18n no_automation />}
      </CardContent>
    </Card>
  );
}

export default function (props) {
  const { open, onClose, bank_account } = props;

  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={bank_account.name}
      avatar={bank_account.image}
    >
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
