import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Switch from "@mui/material/Switch";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import Confirm from "../../common/Confirm";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import BankCard from "./BankCard";
import ParamSchema from "./ParamSchema";
import currencyName from "../../../utils/currency-name";

const CURRENCIES = i18n.t("currencies");

function Step1_SelectBank({ banks, bank_accounts, selectBank, onSelectBank }) {
  // If the same bank has already been integrated, obtain the identifier of the credentials.
  const identifier = bank_accounts
    .filter(
      (bank_account) => selectBank && bank_account.bank.id == selectBank.id,
    )
    .map((bank_account) => bank_account.identifier)[0];
  // Open popup window to perform OAuth authentication process
  const popupIntegrationWindow = () => {
    const url = identifier
      ? routes.select_account_integrations({ identifier })
      : routes.initiate_account_integrations({ bank_id: selectBank.id });
    window.open(
      url,
      "CashWise - " + i18n.t("account_integration"),
      [
        "height=700",
        "width=550",
        "menubar=no",
        "toolbar=no",
        "location=no",
        "status=no",
        "resizable=no",
      ].join(","),
    );
  };

  return (
    <StepContent>
      <Stack spacing={2}>
        {banks.map((bank) => (
          <BankCard
            key={bank.id}
            bank={bank}
            select={selectBank}
            onSelectBank={onSelectBank}
          />
        ))}
      </Stack>
      <Box sx={{ mt: 2 }}>
        <SubmitButton
          fullWidth
          disabled={!selectBank}
          variant="contained"
          sx={{ mr: 1 }}
          onClick={popupIntegrationWindow}
        >
          <I18n connect />
        </SubmitButton>
      </Box>
    </StepContent>
  );
}

function Step3_OperationalSettings({ bank, currency, identifier, handlePrev }) {
  const [openSwitch, setOpenSwitch] = useState(false);
  const openSwitchConfirm = () => setOpenSwitch(true);
  const onCloseSwitchConfirm = () => setOpenSwitch(false);
  const SCHEMA = ParamSchema(currency);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      min_balance: 1000,
      min_transfer: 10000,
      threshold_balance: 500,
      n_days_holding: 3,
      active: true,
    },
    resolver: yupResolver(SCHEMA),
  });
  const active = watch("active");
  const min_balance = watch("min_balance");
  const min_transfer = watch("min_transfer");
  const n_days_holding = watch("n_days_holding");
  const toggleActive = () => {
    if (active) openSwitchConfirm();
    setValue("active", !active);
  };

  return (
    <StepContent>
      <Form
        action={routes.account_integrations()}
        method="post"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <input type="hidden" name="bank_id" value={bank?.id} />
        <input type="hidden" name="currency" value={currency} />
        <input type="hidden" name="identifier" value={identifier} />
        <Stack spacing={2}>
          <BankCard
            bank={bank}
            select={true}
            action={
              <Switch name="active" checked={active} onChange={toggleActive} />
            }
          >
            <Typography variant="body2">
              <I18n currency />
              :&nbsp;{currencyName(currency)}
            </Typography>
          </BankCard>
          <div />
          {active && (
            <Stack spacing={2}>
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                inputProps={{ step: 0.01 }}
                label={i18n.t("minimum_daily_balance_to_be_maintained") + ":"}
                helperText={errors.min_balance?.message}
                {...register("min_balance")}
                error={"min_balance" in errors}
                autoFocus
              />
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                inputProps={{ step: 0.01 }}
                label={i18n.t("minimum_transfer_amount_between_accounts") + ":"}
                helperText={errors.min_balance?.message}
                {...register("min_transfer")}
                error={"min_transfer" in errors}
              />
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                inputProps={{ step: 0.01 }}
                label={i18n.t("threshold_alert_if_balance_below") + ":"}
                helperText={errors.min_balance?.message}
                {...register("threshold_balance")}
                error={"threshold_balance" in errors}
              />
              <TextField
                fullWidth
                required
                variant="standard"
                type="number"
                inputProps={{ step: 1 }}
                label={i18n.t("n_days_holding_funds") + ":"}
                helperText={errors.n_days_holding?.message}
                {...register("n_days_holding")}
                error={"n_days_holding" in errors}
              />
              <Typography variant="subtitle2">
                <I18n
                  automation_threshold_subtitle_1
                  amount={i18n.numberToRounded(parseFloat(min_balance), {
                    delimiter: ",",
                    precision: 2,
                  })}
                />
                <ol>
                  <li>
                    <I18n
                      automation_threshold_subtitle_2
                      amount={i18n.numberToRounded(
                        parseFloat(min_balance) + parseFloat(min_transfer),
                        { delimiter: ",", precision: 2 },
                      )}
                    />
                  </li>
                  <li>
                    {(parseFloat(min_balance) > parseFloat(min_transfer) && (
                      <I18n
                        automation_threshold_subtitle_3a
                        amount={i18n.numberToRounded(
                          parseFloat(min_balance) - parseFloat(min_transfer),
                          { delimiter: ",", precision: 2 },
                        )}
                      />
                    )) || (
                      <I18n
                        automation_threshold_subtitle_3b
                        amount={i18n.numberToRounded(parseFloat(min_transfer), {
                          delimiter: ",",
                          precision: 2,
                        })}
                      />
                    )}
                  </li>
                </ol>
                <I18n automation_threshold_subtitle_4 />
                <br />
                <I18n n_days_holding_subtitle days={n_days_holding} />
              </Typography>
            </Stack>
          )}
        </Stack>
        <Box sx={{ mt: 2 }}>
          <SubmitButton
            fullWidth
            variant="contained"
            type="submit"
            disabled={!identifier || !currency}
          >
            <I18n create />
          </SubmitButton>
          <Button onClick={handlePrev}>
            <I18n prev />
          </Button>
        </Box>
        <Confirm
          open={openSwitch}
          onClose={onCloseSwitchConfirm}
          title={i18n.t("bank_account_integration_switch_to_off")}
          actions={[
            {
              onClick: onCloseSwitchConfirm,
              label: i18n.t("confirm"),
            },
          ]}
        />
      </Form>
    </StepContent>
  );
}

function NewDrawerContent({ onClose, banks, bank_accounts }) {
  const [step, setStep] = useState(0);
  const [selectBank, setSelectBank] = useState(null);
  const [currency, setCurrency] = useState(CURRENCIES[0].value);
  const [identifier, setIdentifier] = useState();
  const onSelectBank = (bank) =>
    selectBank?.id == bank.id ? setSelectBank(null) : setSelectBank(bank);
  window.handleAccountIntegrationSelect = function (_identifier, _currency) {
    setStep(2);
    setCurrency(_currency);
    setIdentifier(_identifier);
  };
  window.handleAccountIntegrationCancel = function () {
    onClose();
  };

  return (
    <Stepper orientation="vertical" activeStep={step}>
      <Step>
        <StepLabel>
          <I18n select_bank />
        </StepLabel>
        <Step1_SelectBank
          banks={banks}
          bank_accounts={bank_accounts}
          selectBank={selectBank}
          onSelectBank={onSelectBank}
        />
      </Step>
      <Step>
        <StepLabel>
          <I18n select_currency />
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <I18n configure_operational_settings />
        </StepLabel>
        <Step3_OperationalSettings
          bank={selectBank}
          currency={currency}
          identifier={identifier}
          handlePrev={() => setStep(0)}
        />
      </Step>
    </Stepper>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("integrations")}
      icon={PlusCircleIcon}
    >
      <NewDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
