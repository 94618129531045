import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import i18n from "../../../utils/i18n";

export default function ({
  bank,
  select,
  onSelectBank,
  action,
  suspended,
  children,
}) {
  return (
    <Card
      onClick={onSelectBank && (() => onSelectBank(bank))}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        ...(suspended ? { opacity: 0.7 } : {}),
        ...(onSelectBank
          ? {
              cursor: "pointer",
              "&:hover": { border: 0.5, borderColor: "#6366F1" },
            }
          : {}),
        ...(bank.id == select?.id || select === true
          ? { border: 2, borderColor: "#6366F1" }
          : {}),
      }}
    >
      <Avatar
        src={bank.image}
        variant="rounded"
        sx={{ height: 56, width: 56, alignSelf: "flex-start" }}
      />
      <div style={{ marginLeft: 24, marginRight: "auto" }}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" sx={{ display: "block" }}>
            {bank.short_name || bank.name}
          </Typography>
          {(bank.bank_type == "transactional" ||
            bank.bank_type == "pseudo_transactional") && (
            <Chip
              size="small"
              label={i18n.t(`bank_type_${bank.bank_type}`)}
              color={bank.bank_type == "transactional" ? "primary" : undefined}
              sx={{ ml: 1, fontSize: "65%" }}
            />
          )}
        </div>
        <div style={{ paddingTop: 4 }}>{children}</div>
      </div>
      {action && <div style={{ alignSelf: "flex-start" }}>{action}</div>}
    </Card>
  );
}
