import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import SvgIcon from "@mui/material/SvgIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { AccountApplicationLayout } from "../../layouts/AccountApplicationLayout";
import I18n from "../../common/I18n";
import Confirm from "../../common/Confirm";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import NewDrawer from "./NewDrawer";
import EditDrawer from "./EditDrawer";
import upcaseFirst from "../../../utils/upcase-first";

export default function ({ users, ...props }) {
  const { current_user } = props;
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const onNewClose = () => setOpenNew(false);
  const onNewClick = () => setOpenNew(true);
  const onEditClose = () => setOpenEdit(false);
  const onEditClick = (user) => setOpenEdit(user);
  const onDeleteClose = () => setOpenDelete(false);
  const onDeleteClick = (user) => setOpenDelete(user);

  return (
    <AccountApplicationLayout {...props}>
      <Stack spacing={2}>
        <Card>
          <CardHeader
            title={i18n.t("users")}
            action={
              <Button onClick={onNewClick} variant="contained" sx={{ px: 4 }}>
                + <I18n invite />
              </Button>
            }
          />
          <TableContainer>
            <Table>
              <colgroup>
                <col width="70px" />
                <col width="30%" />
                <col width="35%" />
                <col width="130px" />
                <col width="96px" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <I18n user_name />
                  </TableCell>
                  <TableCell>
                    <I18n email_address />
                  </TableCell>
                  <TableCell align="center">
                    <I18n role />
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell align="center">
                      <Avatar
                        sx={{
                          height: 32,
                          width: 32,
                        }}
                        src={(!user.inviting && user.image) || null}
                      />
                    </TableCell>
                    <TableCell>
                      <Link onClick={() => onEditClick(user)} underline="hover">
                        {user.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography variant="monospace">{user.email}</Typography>
                      {user.inviting && (
                        <Chip
                          label={i18n.t("inviting")}
                          color="warning"
                          variant="contained"
                          size="small"
                          sx={{ ml: 2 }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {upcaseFirst(user.role)}
                    </TableCell>
                    <TableCell align="center">
                      {user.id != current_user.id &&
                        (user.role == "Owner"
                          ? current_user.role == "Owner"
                          : true) && (
                          <IconButton
                            size="small"
                            onClick={() => onDeleteClick(user)}
                          >
                            <SvgIcon>
                              <TrashIcon />
                            </SvgIcon>
                          </IconButton>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>
      <NewDrawer
        excludeOwner={current_user.role != "Owner"}
        open={openNew}
        onClose={onNewClose}
      />
      <EditDrawer
        open={openEdit}
        current_user={current_user}
        user={openEdit}
        excludeOwner={current_user.role != "Owner"}
        onClose={onEditClose}
      />
      <Confirm
        open={!!openDelete}
        onClose={onDeleteClose}
        title={i18n.t("delete_user")}
        actions={[
          {
            onClick: onDeleteClose,
            label: i18n.t("cancel"),
          },
          {
            href: routes.account_user({ id: openDelete?.id }),
            variant: "contained",
            color: "error",
            label: i18n.t("delete"),
            method: "delete",
          },
        ]}
      >
        <I18n are_you_sure_to_delete_model model={openDelete?.name} />
        <br />({openDelete?.email})<br />
        <div />
        <br />
        <I18n this_action_cannot_be_undone />
      </Confirm>
    </AccountApplicationLayout>
  );
}
