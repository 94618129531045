import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SvgIcon from "@mui/material/SvgIcon";
import { AttentionSeeker } from "react-awesome-reveal";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import ChevronRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";
import CurrencyDollarIcon from "@heroicons/react/24/solid/CurrencyDollarIcon";
import ArrowsRightLeftIcon from "@heroicons/react/24/solid/ArrowsRightLeftIcon";
import dayjs from "dayjs";
import { ApplicationLayout } from "../layouts/ApplicationLayout";
import WarningTooltip from "../common/WarningTooltip";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import TransactionFormDrawer from "./FormDrawer";
import ShowDrawer from "./ShowDrawer";
import TransactionRecord from "./TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import CashflowFormDrawer from "../cashflows/FormDrawer";
import CashflowChart from "./CashflowChart";
import AlertDrawer from "../dashboard/AlertDrawer";
import currencyName from "../../utils/currency-name";
import { Badge } from "@mui/material";
import debounce from "lodash/debounce";

const CURRENCIES = i18n.t("currencies");

export default function ({
  upcoming_cashflow_items,
  scheduled_cashflow_items,
  upcoming_transactions,
  scheduled_transactions,
  bank_accounts,
  current_currency,
  src_bank_accounts,
  dst_bank_accounts,
  balance_src_bank_accounts,
  balance_dst_bank_accounts,
  fx_rates,
  max_n_days_fund_transfer,
  ...props
}) {
  const {
    pending_cashflow_items,
    failed_cashflow_items,
    failed_transactions,
    account_balance_warnings,
    current_user,
    account_admin,
  } = props;
  const currencies = CURRENCIES.filter((currency) =>
    [
      ...new Set(
        dst_bank_accounts
          .concat(src_bank_accounts)
          .map((bank_account) => bank_account.currency),
      ),
    ].includes(currency.value),
  ).map((currency) => currency.value);
  const [currencyTab, setCurrencyTab] = useState(
    current_currency || currencies[0],
  );
  const onChangeTab = (_, value) => setCurrencyTab(value);
  const [upcomingOpen, setUpcomingOpen] = useState(false);
  const [transactionFormOpen, setTransactionFormOpen] = useState(false);
  const [cashflowFormOpen, setCashflowFormOpen] = useState(false);
  const [speedDial, setSpeedDial] = useState(false);
  const [alertDrawer, setAlertDrawer] = useState(false);
  const openUpcoming = () => setUpcomingOpen(true);
  const closeUpcoming = () => setUpcomingOpen(false);
  const openTransactionForm = (transaction) =>
    setTransactionFormOpen(transaction || true);
  const closeTransactionForm = () => {
    if (location.search.length > 1) location.search = "";
    setTransactionFormOpen(false);
  };
  const openCashflowForm = (cashflow_item) =>
    setCashflowFormOpen(cashflow_item || true);
  const closeCashflowForm = () => {
    if (location.search.length > 1) location.search = "";
    setCashflowFormOpen(false);
  };
  const switchToTransactionForm = () => {
    closeCashflowForm();
    openTransactionForm();
  };
  const openSpeedDial = () => setSpeedDial(true);
  const closeSpeedDial = debounce(() => setSpeedDial(false), 3000);
  const closeImmediateSpeedDial = () => setSpeedDial(false);
  const openAlertDrawer = () => setAlertDrawer(true);
  const closeAlertDrawer = () => setAlertDrawer(false);
  const defaultDay = dayjs().add(max_n_days_fund_transfer, "day");
  const scheduled_cashflow_items_currency = scheduled_cashflow_items.filter(
    (item) => item.currency == currencyTab && item.status != "canceled",
  );
  const scheduled_transactions_currency = scheduled_transactions.filter(
    (item) =>
      item.status != "canceled" &&
      (item.src_currency == currencyTab || item.dst_currency == currencyTab),
  );
  const upcoming_cashflow_items_currency = upcoming_cashflow_items.filter(
    (item) => item.bank_account.currency == currencyTab,
  );
  const upcoming_transactions_currency = upcoming_transactions.filter(
    (item) =>
      item.src_currency == currencyTab || item.dst_currency == currencyTab,
  );
  const upcomingItems = upcoming_cashflow_items_currency
    .concat(scheduled_cashflow_items_currency)
    .concat(scheduled_transactions_currency)
    .concat(upcoming_transactions_currency)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const scheduledItems = scheduled_cashflow_items
    .filter((item) => item.bank_account.currency == currencyTab)
    .concat(
      scheduled_transactions.filter(
        (item) =>
          item.src_currency == currencyTab || item.dst_currency == currencyTab,
      ),
    )
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const showWarning =
    pending_cashflow_items.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0 ||
    failed_cashflow_items.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0 ||
    failed_transactions.filter(
      (item) =>
        item.src_currency == currencyTab || item.dst_currency == currencyTab,
    ).length > 0 ||
    account_balance_warnings.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0;

  return (
    <ApplicationLayout {...props}>
      <Stack spacing={2}>
        <Typography variant="h2">
          <I18n cash_planning />
        </Typography>
        <Tabs value={currencyTab} onChange={onChangeTab}>
          {currencies.map((currency) => (
            <Tab
              key={currency}
              value={currency}
              label={currencyName(currency)}
              sx={{ px: 1 }}
            />
          ))}
        </Tabs>
        <Divider sx={{ marginTop: "0!important" }} />
        <div style={{ position: "relative", paddingTop: 8 }}>
          <CashflowChart
            currency={currencyTab}
            upcoming_cashflow_items={upcoming_cashflow_items_currency}
            upcoming_transactions={upcoming_transactions_currency}
            scheduled_cashflow_items={scheduled_cashflow_items_currency}
            scheduled_transactions={scheduled_transactions_currency}
            onEditClick={openUpcoming}
            onNewClick={openSpeedDial}
            fx_rates={fx_rates}
          />
          <Backdrop
            open={speedDial}
            onClick={closeImmediateSpeedDial}
            sx={{
              position: "absolute",
              top: 8,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(230,230,230,0.6)",
            }}
          />
          <SpeedDial
            direction="down"
            ariaLabel="add"
            icon={<SpeedDialIcon />}
            open={speedDial}
            onOpen={openSpeedDial}
            onClose={closeSpeedDial}
            sx={{ position: "absolute", top: 16, right: 16 }}
          >
            {[
              {
                icon: <CurrencyDollarIcon />,
                title: "cash_flow_item",
                onClick: openCashflowForm,
              },
              {
                icon: <ArrowsRightLeftIcon />,
                title: "fx_transaction",
                onClick: openTransactionForm,
              },
            ].map((item, i) => (
              <SpeedDialAction
                key={i}
                icon={<SvgIcon>{item.icon}</SvgIcon>}
                tooltipTitle={i18n.t(item.title)}
                onClick={() => item.onClick()}
              />
            ))}
          </SpeedDial>
        </div>

        <Card>
          <CardHeader
            title={i18n.t("scheduled_transactions")}
            subheader={i18n.t("scheduled_transactions_subtitle")}
            action={
              showWarning && (
                <AttentionSeeker effect="flash" delay={2000}>
                  <WarningTooltip
                    arrow
                    placement="left"
                    title={i18n.t("action_required")}
                  >
                    <IconButton onClick={openAlertDrawer} color="error">
                      <SvgIcon>
                        <ExclamationTriangleIcon />
                      </SvgIcon>
                    </IconButton>
                  </WarningTooltip>
                </AttentionSeeker>
              )
            }
          />
          <Divider />
          <CardContent>
            {scheduledItems.map(
              (item, i) =>
                (item.date && (
                  <CashflowItem
                    key={i}
                    item={item}
                    fx_rates={fx_rates}
                    currency={currencyTab}
                    onClick={() => openCashflowForm(item)}
                  />
                )) || (
                  <TransactionRecord
                    key={i}
                    transaction={item}
                    currency={currencyTab}
                    readOnly={current_user.role_value == "read_only"}
                    fx_rates={fx_rates}
                    onClick={
                      item.status == "placed"
                        ? () => openTransactionForm(item)
                        : undefined
                    }
                  />
                ),
            )}
            {scheduledItems.length == 0 && (
              <Typography variant="subtitle2">
                <I18n no_scheduled_automation />
              </Typography>
            )}
          </CardContent>
        </Card>

        <Card onClick={openUpcoming} sx={{ cursor: "pointer" }}>
          <CardHeader
            title={
              <Badge badgeContent={upcomingItems.length} color="primary">
                {i18n.t("projected_transactions")}
                &nbsp;&nbsp;
              </Badge>
            }
            subheader={i18n.t("projected_transactions_subtitle")}
            action={
              <IconButton onClick={openUpcoming}>
                <SvgIcon>
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            }
          />
        </Card>
      </Stack>

      <AlertDrawer
        open={alertDrawer}
        onClose={closeAlertDrawer}
        pending_cashflow_items={pending_cashflow_items}
        failed_cashflow_items={failed_cashflow_items}
        failed_transactions={failed_transactions}
        account_balance_warnings={account_balance_warnings}
        current_user={current_user}
      />
      <ShowDrawer
        open={upcomingOpen}
        onClose={closeUpcoming}
        fx_rates={fx_rates}
        currency={currencyTab}
        current_user={current_user}
        upcomingItems={upcomingItems}
        openCashflowForm={openCashflowForm}
        openTransactionForm={openTransactionForm}
      />
      <TransactionFormDrawer
        open={!!transactionFormOpen}
        onClose={closeTransactionForm}
        defaultCurrency={currencyTab}
        item={transactionFormOpen === true ? null : transactionFormOpen}
        fx_rates={fx_rates}
        src_bank_accounts={src_bank_accounts}
        dst_bank_accounts={dst_bank_accounts}
        balance_src_bank_accounts={balance_src_bank_accounts}
        balance_dst_bank_accounts={balance_dst_bank_accounts}
        current_user={current_user}
        account_admin={account_admin}
      />
      <CashflowFormDrawer
        open={!!cashflowFormOpen}
        onClose={closeCashflowForm}
        switchToTransactionForm={switchToTransactionForm}
        item={cashflowFormOpen === true ? null : cashflowFormOpen}
        bank_accounts={bank_accounts}
        defaultCurrency={currencyTab}
        defaultDay={defaultDay}
        current_user={current_user}
        account_admin={account_admin}
      />
    </ApplicationLayout>
  );
}
