import React, { useState, useEffect } from "react";
import BuildingOffice2Icon from "@heroicons/react/24/solid/BuildingOffice2Icon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import routes from "../../../utils/routes";
import api from "../../../utils/api";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import I18n from "../../common/I18n";

function ShowDrawerContent({ account }) {
  const [response, setResponse] = useState(undefined);
  useEffect(() => {
    api({
      method: "get",
      url: routes.console_account({ id: account.id }),
      onSuccess: (_response) => {
        setResponse(_response);
      },
    });
  }, []);

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title="Users" />
        <Divider />
        <CardContent>
          {response?.users.map((user) => (
            <Grid key={user.id} container>
              <Grid item xs={2} md={1} sx={{ py: 1 }}>
                <Avatar
                  src={user.image}
                  variant="circle"
                  sx={{ height: 32, width: 32 }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1">
                  <b>{user.name}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2">{user.email}</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2">{user.role}</Typography>
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Bank Accounts" />
        <Divider />
        <Grid container sx={{ my: 2 }}>
          {response && response.bank_account_balances?.length == 0 && (
            <Grid item xs={12} sx={{ p: 4 }}>
              <Typography variant="body1">No bank accounts</Typography>
            </Grid>
          )}
          {response?.bank_account_balances?.map((balance, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={6}
              sx={{
                px: 3,
                py: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={balance.bank_account.bank.image}
                  variant="rounded"
                  sx={{ mr: 1, width: 24, height: 24 }}
                />
                <Typography variant="body2">
                  {balance.bank_account.bank.name}
                  {balance.bank_account.profile.suspended && " (suspended)"}
                </Typography>
              </div>
              <div>
                <Typography variant="body2">
                  <b>
                    <I18n
                      l="currency"
                      v={balance.account_balance.amount}
                      currency={balance.bank_account.currency}
                    />
                  </b>
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, account } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={account.name}
      icon={BuildingOffice2Icon}
    >
      <ShowDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
