import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import "simplebar-react/dist/simplebar.min.css";
import { createTheme } from "../theme";
import Flash from "../common/Flash";
import { SideNavigation } from "./SideNavigation";
import { TopNavigation } from "./TopNavigation";
import { MainMenu, AdminMenu } from "../layouts/ApplicationMenu";
import { LayoutRoot, LayoutContainer } from "./LayoutContainer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";

export const ApplicationLayout = function ({
  current_user,
  account,
  flash,
  children,
  account_admin,
  notification,
  notifications,
  pending_cashflow_items,
  failed_cashflow_items,
  failed_transactions,
  account_balance_warnings,
  expiring_bank_account_credentials,
  expiring_app_integrations,
  show_alert,
}) {
  const [openNav, setOpenNav] = useState(false);
  const theme = createTheme();
  // Open popup window to perform OAuth authentication process
  const popupIntegrationWindow = () => {
    const url = routes.initiate_account_integrations({
      identifier:
        expiring_bank_account_credentials &&
        expiring_bank_account_credentials[0],
    });
    const popup = window.open(
      url,
      "CashWise - " + i18n.t("account_integration"),
      [
        "height=700",
        "width=550",
        "menubar=no",
        "toolbar=no",
        "location=no",
        "status=no",
        "resizable=no",
      ].join(","),
    );
    popup.onbeforeunload = () => location.reload();
  };
  const popupAppIntegrationWindow = () => {
    const url = routes.initiate_account_app_integrations({
      identifier: expiring_app_integrations && expiring_app_integrations[0],
    });
    const popup = window.open(
      url,
      "CashWise - " + i18n.t("app_integration"),
      [
        "height=700",
        "width=550",
        "menubar=no",
        "toolbar=no",
        "location=no",
        "status=no",
        "resizable=no",
      ].join(","),
    );
    popup.onbeforeunload = () => location.reload();
  };
  if (expiring_app_integrations && expiring_app_integrations.length > 0)
    window.handleAppIntegrationSelect = function () {};

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <TopNavigation
          onNavOpen={() => setOpenNav(true)}
          current_user={current_user}
          account={account}
          notification={notification}
          notifications={notifications}
          pending_cashflow_items={pending_cashflow_items}
          failed_cashflow_items={failed_cashflow_items}
          failed_transactions={failed_transactions}
          account_balance_warnings={account_balance_warnings}
          show_alert={show_alert}
        />
        <SideNavigation
          onClose={() => setOpenNav(false)}
          open={openNav}
          current_user={current_user}
          menu={MainMenu}
          adminMenu={account_admin && AdminMenu}
        />
        <LayoutRoot>
          <LayoutContainer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 4,
              }}
            >
              {expiring_bank_account_credentials &&
                expiring_bank_account_credentials.length > 0 && (
                  <Alert
                    severity="warning"
                    sx={{ alignItems: "center", marginY: 1 }}
                    action={
                      <Button
                        size="small"
                        color="warning"
                        variant="contained"
                        onClick={popupIntegrationWindow}
                      >
                        <I18n update_credentials />
                      </Button>
                    }
                  >
                    <AlertTitle>
                      <I18n action_required />
                    </AlertTitle>
                    <I18n
                      account_integration_credentials_need_to_be_updated
                      name={i18n.t("bank_account")}
                    />
                  </Alert>
                )}
              {expiring_app_integrations &&
                expiring_app_integrations.length > 0 && (
                  <Alert
                    severity="warning"
                    sx={{ alignItems: "center", marginY: 1 }}
                    action={
                      <Button
                        size="small"
                        color="warning"
                        variant="contained"
                        onClick={popupAppIntegrationWindow}
                      >
                        <I18n update_credentials />
                      </Button>
                    }
                  >
                    <AlertTitle>
                      <I18n action_required />
                    </AlertTitle>
                    <I18n
                      account_app_integration_credentials_need_to_be_updated
                      name={i18n.t("app_integration")}
                    />
                  </Alert>
                )}
              <Flash flash={flash} />
              <Container maxWidth="lg">{children}</Container>
            </Box>
          </LayoutContainer>
        </LayoutRoot>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
